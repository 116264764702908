import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePriceAction, getPriceAction, saveCostAction, getCostAction  } from "../redux/orders";
import { currency } from "../helpers/Formater"
import Header from "./Header";
import Nav from "./Nav";
import Modal from "./Modal";
import Moment from "moment";
import "moment-timezone";

export default function Price() {
  const savingPrice = useSelector((store) => store.order.savingPrice);
  const savingCost = useSelector((store) => store.order.savingCost);
  const user = useSelector((store) => store.user.data);
  const dispatch = useDispatch();
  const message = useSelector((store) => store.order.message);
  const currentPrice = useSelector((store) => store.order.currentPrice);
  const currentCost = useSelector((store) => store.order.currentCost);
  const [submitted, setSubmitted] = useState(false);
  const [submittedCost, setSubmittedCost] = useState(false);
  
  const [price, setPrice] = useState("");
  const [cost, setCost] = useState({
    TypeCostId:1
  });
  useEffect(() => {
    dispatch(getPriceAction());
    dispatch(getCostAction())
  }, [dispatch]);

  const handleChange = (e) => {
    const { value } = e.target;
    setPrice(value);
  };
  const handleCostChange = (e) => {
    const { value } = e.target;
    console.log(value);
    setCost((prev) => {return{...prev, PriceInCents:value}});
  };

  const parseDate = (date) => {
    var zone = Moment.tz("America/Mexico_city").format("Z");
    var zoneValue = zone.replace(/[^0-9: ]/g, ""); // Zone value without + - chars
    var operator =
      zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+"; // operator for addition subtraction
    var localDateTime;
    var hours = zoneValue.split(":")[0];
    var minutes = zoneValue.split(":")[1];
    if (operator === "-") {
      localDateTime = Moment(date)
        .subtract(hours, "hours")
        .subtract(minutes, "minutes")
        .format("DD-MM-YYYY");
    } else if (operator) {
      localDateTime = Moment(date)
        .add(hours, "hours")
        .add(minutes, "minutes")
        .format("DD-MM-YYYY");
    } else {
      localDateTime = "Invalid Timezone Operator";
    }
    return localDateTime;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    setSubmitted(true);
    if (price) {
      dispatch(savePriceAction(price));
      //this.props.login(username, password);
    }
  };
  const handleSubmitCost = (e) => {
    e.preventDefault();
    console.log(e);
    setSubmittedCost(true);
    if (cost) {
      dispatch(saveCostAction(cost));
    }
  };
  return (
    <div className="container-scroller">
      <Header name={`${user.firstName} ${user.lastName}`}></Header>
      <div className="container-fluid page-body-wrapper">
        <Nav></Nav>
        <div className="main-panel">
          <div className="content-wrapper-o">
            <div className="row">
              <div className="col-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Precio del gas</h4>
                    <p className="card-description">
                      Ingresa el precio actual de la unidad de gas.
                    </p>
                    <form
                      name="form"
                      onSubmit={handleSubmit}
                      className={
                        "form-inline " +
                        (submitted && !price ? "was-validated" : "")
                      }
                      noValidate
                    >
                      <fieldset disabled={savingPrice && "disabled"}>
                        <input
                          type="text"
                          name="price"
                          onChange={handleChange}
                          className="form-control mb-2 mr-sm-2"
                          id="inlineFormInputName2"
                          placeholder="Precio"
                          required
                        />
                        <button
                          type="submit"
                          className="btn btn-gradient-primary mb-2"
                        >
                          {savingPrice ? (
                            <img
                              alt="loading"
                              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            />
                          ) : (
                            "Guardar"
                          )}
                        </button>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Precio actual</h4>
                    <p className="card-description">
                      Precio actual establecido el {parseDate(currentPrice.date)}.
                    </p>
                    <div className="h1">{currency(currentPrice.price)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Precio del cargo por uso de plataforma</h4>
                    <p className="card-description">
                      Ingresa el precio actual del cargo por uso de plataforma.
                    </p>
                    <form
                      name="form"
                      onSubmit={handleSubmitCost}
                      className={
                        "form-inline " +
                        (submittedCost && !cost ? "was-validated" : "")
                      }
                      noValidate
                    >
                      <fieldset disabled={savingCost && "disabled"}>
                        <input
                          type="text"
                          name="cost"
                          onChange={handleCostChange}
                          className="form-control mb-2 mr-sm-2"
                          id="inlineFormInputName2"
                          placeholder="Cargo por servicio"
                          required
                        />
                        <button
                          type="submit"
                          className="btn btn-gradient-primary mb-2"
                        >
                          {savingCost ? (
                            <img
                              alt="loading"
                              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            />
                          ) : (
                            "Guardar"
                          )}
                        </button>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Precio actual del cargo por uso de plataforma</h4>
                    <p className="card-description">
                      Precio actual establecido el {parseDate(currentCost?.cost?.date)}.
                    </p>
                    <div className="h1">{currency(currentCost?.cost?.price)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {message && <Modal message={message} openModal={true}></Modal>}
      </div>
    </div>
  );
}
