import React from "react";
import Header from "./Header";
import Nav from "./Nav";
import { useSelector } from "react-redux";
import SearchOrders from "./SearchOrders";

export default function Home() {
  const user = useSelector((store) => store.user.data);
  return (
    <div className="container-scroller">
      <Header name={`${user.firstName} ${user.lastName}`}></Header>
      <div className="container-fluid page-body-wrapper">
        {user.role === "Admin" && <Nav></Nav>}
        <SearchOrders></SearchOrders>
      </div>
    </div>
  );
}
