import React, { useEffect, useState } from "react";
import {
  getOrdersCompletedAction,
  getOrderCompletedAction,
  resetSearchBox,
} from "../redux/orders";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import { DataGrid } from "@material-ui/data-grid";
import { TranslatePaymentMethod } from "../helpers/Orders";
import { mdiCloseCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import Modal from "./Modal";

export default function OrdersCompletedList() {
  const fetchingOrder = useSelector((store) => store.order.fetchingOrder);
  const dispatch = useDispatch();
  const [showDetail, setShowDetail] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [loadOrders, setLoadOrders] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [folio, setFolio] = useState("");
  const orders = useSelector((store) => store.order.ordersCompletedList);
  const message = useSelector((store) => store.order.message);
  const searchOrder = useSelector((store) => store.order.searchOrder);
  const finalizeOrderDone = useSelector(
    (store) => store.order.finalizeOrderDone
  );
  const finalizeOrderRequest = useSelector(
    (store) => store.order.finalizeOrderRequest
  );
  const loading = useSelector((store) => store.order.loading);

  useEffect(() => {
    if (loadOrders || finalizeOrderDone) {
      dispatch(getOrdersCompletedAction());
      setLoadOrders(false);
    }
  }, [dispatch, loadOrders, setLoadOrders, finalizeOrderDone]);

  const handleCellClick = (event) => {
    console.log(event);
    setOrderDetail(event.data);
    setShowDetail(true);
  };

  const CancelButton = () => {
    setShowDetail(false);
  };

  const columns = [
    { field: "folio", headerName: "Folio", width: 100 },
    { field: "userName", headerName: "Nombre", width: 250 },
    {
      field: "orderDate",
      headerName: "Fecha",
      width: 130,
      valueFormatter: ({ value }) => Moment(value).format("DD-MM-YYYY"),
    },
    { field: "amount", headerName: "Litros", width: 130 },
    {
      field: "paymentMethod",
      headerName: "Método de pago",
      width: 180,
      valueFormatter: ({ value }) => TranslatePaymentMethod(value),
    },
    {
      field: "paymentTotal",
      headerName: "Total (pesos)",
      width: 180,
    },
    {
      field: "assignedTo",
      headerName: "Asignado",
      width: 130,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (folio) {
      dispatch(getOrderCompletedAction(folio));
      //this.props.login(username, password);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFolio(value);
  };

  const iconHandleClick = (e) => {
    setFolio("");
    dispatch(resetSearchBox());
  };

  const sortModel = [
    {
      field: "orderDate",
      sort: "desc",
    },
  ];

  return (
    <div className="main-panel">
      <div className="content-wrapper-o">
        {loading || finalizeOrderRequest ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {showDetail && (
              <div
                className="modal fade show"
                role="dialog"
                style={{ display: "block", overflowY: "hidden" }}
              >
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={CancelButton}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <blockquote className="blockquote blockquote-primary">
                        <p style={{ textAlign: "center", marginLeft: ".5rem" }}>
                          <b>Consumidor:</b> {orderDetail.userName} {" -  "}
                          <b>Número de folio:</b> {orderDetail.folio} {" -  "}
                          <b>Fecha:</b>{" "}
                          {Moment(orderDetail.orderDate).format("DD-MM-YYYY")}{" "}
                          {" -  "}
                          <b>Cantidad:</b> {orderDetail.amount} {" -  "}
                          <b>Total:</b> {orderDetail.paymentTotal}
                        </p>
                      </blockquote>
                      <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                          <p style={{ textAlign: "center" }}>
                            Foto medidor inicial
                          </p>
                          <img
                            src={orderDetail.evidence.imageBefore}
                            alt="imageBefore"
                          />
                        </div>
                        <div className="col-lg-4 mx-auto">
                          <p style={{ textAlign: "center" }}>
                            Foto medidor final
                          </p>
                          <img
                            src={orderDetail.evidence.imageAfter}
                            alt="imageAfter"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={CancelButton}
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Servicios</h4>
                  <h4 className="card-title">Buscar servicios</h4>
                  <p className="card-description">
                    Ingresa el número de servicio para realizar la búsqueda.
                  </p>
                  <form
                    name="form"
                    onSubmit={handleSubmit}
                    className={
                      "form-inline " +
                      (submitted && !folio ? "was-validated" : "")
                    }
                    noValidate
                  >
                    <fieldset disabled={fetchingOrder && "disabled"}>
                      <Icon
                        style={{ cursor: "pointer" }}
                        path={mdiCloseCircleOutline}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="red"
                        onClick={iconHandleClick}
                      />
                      <input
                        type="text"
                        name="folio"
                        onChange={handleChange}
                        className="form-control mb-2 mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder="Número de servicio"
                        value={folio}
                        required
                      />

                      <button
                        type="submit"
                        className="btn btn-gradient-primary mb-2"
                      >
                        {fetchingOrder ? (
                          <img
                            alt="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                          />
                        ) : (
                          "Buscar"
                        )}
                      </button>
                    </fieldset>
                  </form>
                  {loading || finalizeOrderRequest ? (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        sortModel={sortModel}
                        rows={
                          searchOrder === null || folio === ""
                            ? orders
                            : [searchOrder]
                        }
                        columns={columns}
                        pageSize={5}
                        onCellClick={handleCellClick}
                        hideFooterSelectedRowCount
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {message && <Modal message={message} openModal={true}></Modal>}
      </div>
    </div>
  );
}
