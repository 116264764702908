import React, { useState, useCallback, useEffect } from "react";
import AWS from "aws-sdk";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import {
  mdiCloudUpload,
  mdiFileTable,
  mdiLoading,
  mdiDelete,
  mdiCheckAll,
  mdiRefresh,
  mdiAlertCircleOutline 
} from "@mdi/js";
import Icon from "@mdi/react";
import { v4 as uuidv4 } from "uuid";
import Modal from "./Modal";
import { useSelector } from "react-redux";

const S3_BUCKET = process.env.REACT_APP_KIWI_BUCKET_NAME;
const REGION = process.env.REACT_APP_KIWI_REGION;
AWS.config.update({
  accessKeyId:  process.env.REACT_APP_KIWI_AK,
  secretAccessKey: process.env.REACT_APP_KIWI_PK,
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const style = {
  backgroundColor: "#f8f9fa",
  border: "2px dashed #CFCCCD",
  borderRadius: " 3px",
  height: "15rem",
  width: "100%",
  padding: "2rem",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

const styleErr = {
  backgroundColor: "#ffdcdc",
  border: "2px dashed #ffb6b6",
  borderRadius: " 3px",
  height: "15rem",
  width: "100%",
  padding: "2rem",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

const styleActBox = {
  backgroundColor: "#cff4fc",
  border: "2px dashed #CFCCCD",
  borderRadius: " 3px",
  height: "15rem",
  width: "100%",
  padding: "2rem",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

const btnDelete = {
  position: "absolute",
  right: "60px",
  marginTop: "-20px",
};

const styleProgress = {
  position: "absolute",
  top: "47%",
  fontSize: "30px",
  fontWeight: "bolder",
};

const styleCopy ={
  margin: '0 20%'
}

export const UserSyncBoxUpload = ({onSuccesUpload}) => {
  const [message, setmessage] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isloading, setIsloading] = useState(false);
  const [droppedFile, setDroppedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [stateLoad, setStateLoad] = useState(0);
  const [error, setError] = useState(null);
  const {username} = useSelector((store) => store.user.data);
  const [noDomainName] = username.split('@')
  let myTimeout = null;
  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        const [file] = item.files;
        if (file.type.includes("csv") || file.name.includes("csv")) {
          if (file.size <= 25000000) {
            setError(null);
            setDroppedFile(file);
          } else {
            setError("El archivo debe pesar menos de 25 MB.");
          }
        } else {
          setError("Solamente puede cargar archivos CSV");
        }
      }
    },
    [setDroppedFile]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (handleFileDrop) {
          handleFileDrop(item);
        }
      },
      canDrop(item) {
        if (item.files.length > 1) {
          return false;
        } else {
          return true;
        }
      },
      hover(item) {
        if (item.files.length > 1) {
          setError("Solo puede arrastrar un archivo. Intentelo una vez más");
          return false;
        } else {
          setError(null);
          return true;
        }
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [handleFileDrop]
  );
  const isActive = canDrop && isOver;

  const uploadByButton = (e) => {
    const [file] = e.target.files;
    if (file.type.includes("csv") || file.name.includes("csv")) {
      if (file.size <= 25000000) {
        setError(null);
        setDroppedFile(file);
      } else {
        setError("El archivo debe pesar menos de 25 MB.");
      }
    } else {
      setError("Solamente puede cargar archivos CSV");
    }
  };

  const clearFile = () => {
    setError(null);
    setDroppedFile(null);
    setProgress(0);
    setIsloading(false);
    setShowModal(false)
    clearTimeout(myTimeout);
  };

  const uploadFile = async () => {
    const uuid = uuidv4();
    const objDate = new Date();
    const dateString = `${objDate.getFullYear()}${String(
      objDate.getMonth()
    ).padStart(2, "00")}${String(objDate.getDate()).padStart(2, "00")}`;
    const timeString = `${String(objDate.getHours()).padStart(2, "00")}${String(
      objDate.getMinutes()
    ).padStart(2, "00")}${String(objDate.getSeconds()).padStart(2, "00")}`;

    setIsloading(true);
    setStateLoad(1);
    const params = {
      Body: droppedFile,
      Bucket: S3_BUCKET,
      Key: `Sync/Users/temp/${dateString}_${timeString}/${noDomainName}/${uuid}_${droppedFile.name}`,
    };

    /*var paramsToCors = {
      Bucket: S3_BUCKET,
      CORSConfiguration: {
        CORSRules: [
            {
              AllowedHeaders: [
                "*"
              ],
              AllowedMethods: [
                "PUT",
                "POST",
                "GET",
                "DELETE"
              ],
              AllowedOrigins: [
                "https://dev-cms.kiwigas.com.mx",
                "https://d2o45t9g5ozdg9.cloudfront.net"
              ],
              ExposeHeaders: [
                "x-amz-server-side-encryption"
              ],
              MaxAgeSeconds: 3000
            }
        ]
      },
      ContentMD5: ""
    };

    myBucket.putBucketCors(paramsToCors, function (err, data) {
      try{
        if (err) console.log(err, err.stack); // an error occurred
        else console.log(data);
      }
      catch(o){

      }
           // successful response
    });*/


    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          setStateLoad(3);
          console.log(err);
        }
      });
  };

  const myStopFunction = () => {
    clearTimeout(myTimeout);
    clearFile();
  };

  useEffect(() => {
    if (progress >= 100) {
      setStateLoad(2);
      onSuccesUpload(1);
      setmessage('Hemos recibido tu solicitud de sincronización, se te notificará vía correo al finalizar el proceso o bien puedes consultar en la plataforma el progreso de tu solicitud.');
      setShowModal(true)
      myTimeout = setTimeout(() => {
        myStopFunction();
      }, 50000);
    }
  }, [progress]);

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Subir archivo</h4>
        <p className="card-description">
          Carga un archivo CSV para actualizar la base de usuarios.
        </p>
        {!isloading && (
          <div
            ref={drop}
            style={error ? styleErr : isActive ? styleActBox : style}
          >
            {droppedFile && !error ? (
              <>
                <button
                  className="btn btn-sm btn-danger"
                  style={btnDelete}
                  onClick={clearFile}
                >
                  <Icon path={mdiDelete} size={1} color="white"></Icon>
                </button>
                <Icon path={mdiFileTable} size={8} color="gray" />
                {droppedFile.name}
              </>
            ) : (
              <>
                <Icon path={mdiCloudUpload} size={8} color="gray" />
                {error ? (
                  error
                ) : (
                  <>
                    {isActive
                      ? "Suelte para seleccionar su archivo."
                      : "Arrastre aqui su archivo o adjuntelo."}
                  </>
                )}
                <label
                  htmlFor="file-upload"
                  className="btn btn-gradient-primary mt-2"
                >
                  Explorar archivos
                </label>
                <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={uploadByButton}
                />
              </>
            )}
          </div>
        )}
        {isloading && (
          <div style={style}>
            <>
              {stateLoad === 1 && (
                <>
                  <Icon path={mdiLoading} size={8} color="gray" spin />
                  <span style={styleProgress}>{progress}%</span>
                  Estamos cargando su archivo, espere por favor.
                </>
              )}
              {stateLoad === 2 && (
                <>
                  <button
                    className="btn btn-gradient-primary btn-sm"
                    style={btnDelete}
                    onClick={clearFile}
                  >
                    <Icon path={mdiCloudUpload} size={1} color="white"></Icon>
                  </button>
                  <Icon path={mdiCheckAll} size={8} color="green" />
                  <span style={styleCopy}>Su archivo se cargo con exito.</span>
                </>
              )}
              {stateLoad === 3 && (
                <>
                  <button
                    className="btn btn-gradient-primary btn-sm"
                    style={btnDelete}
                    onClick={clearFile}
                  >
                    <Icon path={mdiRefresh } size={1} color="white"></Icon>
                  </button>
                  <Icon path={mdiAlertCircleOutline} size={8} color="red" />
                  <span style={styleCopy}>Ocurrio un error al intentar subir su archivo, intentelo más tarde.</span>
                </>
              )}
            </>
          </div>
        )}

        <button
          disabled={droppedFile === null || isloading}
          onClick={uploadFile}
          className="btn btn-gradient-primary mt-2"
        >
          {isloading && (
            <>
              <Icon path={mdiLoading} size={1} color="white" spin />
            </>
          )}
          {!isloading && <>Subir</>}
        </button>
      </div>
      {message && <Modal message={message} openModal={showModal}></Modal>}
    </div>
  );
};
