import React from "react";
import { useSelector } from "react-redux";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Header from "./Header";
import Nav from "./Nav";
import UserSyncPanel from "./UserSyncPanel";
export default function UsersSync() {
    const user = useSelector((store) => store.user.data);
    return (
        <div className="container-scroller">
        <Header name={`${user.firstName} ${user.lastName}`}></Header>
        <div className="container-fluid page-body-wrapper">
          {user.role === "Admin" && <Nav></Nav>}
            <DndProvider backend={HTML5Backend}>
                <UserSyncPanel></UserSyncPanel>
		        </DndProvider>
        </div>
      </div>
    );
}
