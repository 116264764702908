import React from "react";
export default function AuthHeader() {
  let data = JSON.parse(localStorage.getItem("data"));

  if (data && data.token) {
    return {
      Authorization: "Bearer " + data.token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
