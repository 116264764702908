import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Circles from "../assets/images/dashboard/circle.svg";
import Icon from "@mdi/react";
import { mdiCameraEnhanceOutline } from "@mdi/js";
import { saveEvidenceAction } from "../redux/orders";
import Resizer from "react-image-file-resizer";

export default function Evidences(props) {
  const folio = props.folio;
  const [imageBefore, setImageBefore] = useState(null);
  const [imageAfter, setImageAfter] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { saved, loading, message } = useSelector((store) => store.order);

  const dispatch = useDispatch();

  const onFileChange = (event) => {
    let target = event.target;
    if (target.files[0]) {
      resizeFile(target.files[0]).then((img) => {
        console.log("img", img);

        if (target.name === "before")
          setImageBefore({
            name: target.files[0].name,
            type: target.files[0].type,
            toDateString: target.files[0].lastModifiedDate.toDateString(),
            imageTobase64: img.replace(/^data:image\/[a-z]+;base64,/, ""),
          });
        if (target.name === "after")
          setImageAfter({
            name: target.files[0].name,
            type: target.files[0].type,
            toDateString: target.files[0].lastModifiedDate.toDateString(),
            imageTobase64: img.replace(/^data:image\/[a-z]+;base64,/, ""),
          });
      });
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onFileUpload = (event) => {
    event.preventDefault();
    dispatch(
      saveEvidenceAction(
        folio,
        imageBefore.imageTobase64,
        imageAfter.imageTobase64
      )
    );
    setShowModal(true);
  };

  return (
    <div className="row">
      {(saved || message) && showModal && (
        <div
          className="modal fade show"
          id="myModal"
          style={{ display: "block", overflowY: "hidden" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Aviso</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                {message
                  ? { message }
                  : `Las fotos se han guardado correctamente.`}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="col-md-6 stretch-card grid-margin"
        style={{ width: "auto" }}
      >
        <label htmlFor="inputPictureBefore">
          <div
            className="card bg-gradient-danger card-img-holder text-white"
            style={{ minWidth: "100%" }}
          >
            <div className="card-body">
              <img src={Circles} className="card-img-absolute" alt="circle" />
              <h4 className="font-weight-normal mb-3">
                Subir foto de medidor inicial.
                <i className="mdi mdi-chart-line mdi-24px float-right"></i>
              </h4>
              <div className="float-left">
                <Icon
                  path={mdiCameraEnhanceOutline}
                  size={7}
                  horizontal
                  vertical
                  rotate={180}
                  color="white"
                />
              </div>
              <div className="float-right" style={{ marginTop: "10%" }}>
                {imageBefore ? (
                  <div>
                    Name: {imageBefore.name}
                    <br />
                    Type: {imageBefore.type}
                    <br />
                    Last Modified: {imageBefore.toDateString}
                  </div>
                ) : (
                  `No se ha seleccionado ninguna imagen`
                )}
              </div>
            </div>
          </div>
        </label>
        <input
          id="inputPictureBefore"
          name="before"
          style={{ display: "none" }}
          onChange={onFileChange}
          type="file"
          accept=".jpg, .jpeg"
        />
      </div>
      <div className="col-md-6 stretch-card grid-margin">
        <label htmlFor="inputPictureAfter">
          <div
            className="card bg-gradient-info card-img-holder text-white"
            style={{ minWidth: "100%" }}
          >
            <div className="card-body">
              <img src={Circles} className="card-img-absolute" alt="circle" />
              <h4 className="font-weight-normal mb-3">
                Subir foto de medidor final.
                <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
              </h4>
              <Icon
                path={mdiCameraEnhanceOutline}
                size={7}
                horizontal
                vertical
                rotate={180}
                color="white"
              />
              <div className="float-right" style={{ marginTop: "10%" }}>
                {imageAfter ? (
                  <div>
                    Name: {imageAfter.name}
                    <br />
                    Type: {imageAfter.type}
                    <br />
                    Last Modified: {imageAfter.toDateString}
                  </div>
                ) : (
                  `No se ha seleccionado ninguna imagen`
                )}
              </div>
            </div>
          </div>
        </label>
        <input
          id="inputPictureAfter"
          name="after"
          style={{ display: "none" }}
          onChange={onFileChange}
          type="file"
          accept=".jpg, .jpeg"
        />
      </div>
      <div
        className="col-md-12 stretch-card grid-margin"
        style={{ width: "auto" }}
      >
        <button
          onClick={onFileUpload}
          type="submit"
          disabled={!(imageBefore && imageAfter)}
          className="btn btn-gradient-primary mb-2"
        >
          {loading ? (
            <img
              alt="loading"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
          ) : (
            "Guardar"
          )}
        </button>
      </div>
    </div>
  );
}
