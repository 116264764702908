import { login, validateSession, AddUserTemp } from "../services";
import { history } from "../helpers/history";

// constants
const initialState = {
  data: null,
  loading: false,
  message: "",
  loggedIn: false,
  loggingIn: false,
  isUserValid: false,
  addUserRequest: false,
};

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const LOGIN_REQUEST = "LOGIN_REQUEST";
const SET_USER_SUCCESS = "SET_USER_SUCCESS";
const SET_USER_FAILED = "SET_USER_FAILED";
const VALIDATE_USER_SUCCESS = "VALIDATE_USER_SUCCESS";
const VALIDATE_USER_FAILED = "VALIDATE_USER_FAILED";
const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
const ADD_USER_REQUEST = "ADD_USER_REQUEST";
const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
const ADD_USER_FAILED = "ADD_USER_FAILED";
const RESET_ERROR_MESSAGE_USERS = "RESET_ERROR_MESSAGE_USERS";

//actions
export const loginAction = (email, password) => (dispatch, getState) => {
  dispatch({
    type: LOGIN_REQUEST,
  });

  let loginInput = {
    email: email,
    password: password,
  };

  return login(loginInput)
    .then((loginResponse) => {
      if (
        loginResponse.status === 200 &&
        (loginResponse.data.role === "Cms" ||
          loginResponse.data.role === "Admin")
      ) {
        localStorage.setItem("data", JSON.stringify(loginResponse.data));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: loginResponse.data,
        });
        history.push("/");
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload:
            "No tienes permiso para ingresar. Por favor, ponte en contacto con los administradores para solicitar acceso.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_FAILED,
        payload: error.response.data.message,
      });
    });
};

export const logOutAction = () => (dispatch, getState) => {
  localStorage.removeItem("data");
  dispatch({
    type: LOGOUT_USER_SUCCESS,
  });
};

export const setUserAction = () => (dispatch, getState) => {
  var data = JSON.parse(localStorage.getItem("data"));
  if (data != null) {
    dispatch({
      type: SET_USER_SUCCESS,
      payload: data,
    });
    history.push("/");
  } else {
    dispatch({
      type: SET_USER_FAILED,
    });
  }
};

export const validateUserAction = () => (dispatch, getState) => {
  return validateSession()
    .then((validateSessionResponse) => {
      if (validateSessionResponse.status === 200) {
        dispatch({
          type: VALIDATE_USER_SUCCESS,
        });
      }
      if (validateSessionResponse.status === 401) {
        dispatch({
          type: VALIDATE_USER_FAILED,
        });
      }
    })
    .catch((error) => {
      localStorage.removeItem("data");
      dispatch({
        type: VALIDATE_USER_FAILED,
      });
    });
};

export const resetErrorMessageUsers = () => (dispatch) => {
  dispatch({ type: RESET_ERROR_MESSAGE_USERS });
};

export const SaveUserAction = (
  name,
  lastName,
  sgcNumber,
  membershipNumber,
  phoneNumber
) => (dispatch, getState) => {
  let addUserInput = {
    name: name,
    lastName: lastName,
    contractNumber: sgcNumber,
    membership: membershipNumber,
    phoneNumber: phoneNumber,
  };

  dispatch({ type: ADD_USER_REQUEST });

  return AddUserTemp(addUserInput)
    .then((addUserResponse) => {
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: addUserResponse.data.message,
      });
    })
    .catch((error) => {
      dispatch({ type: ADD_USER_FAILED, payload: error.response.data.message });
    });
};

//reducer
export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loggedIn: true,
        loggingIn: false,
        message: "",
        status: { key: "success", message: "", actionId: 0 },
      };
    case SET_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loggedIn: true,
        loggingIn: false,
        status: { key: "success", message: "", actionId: 0 },
      };
    case SET_USER_FAILED:
      return {
        data: null,
        loading: false,
        message: "",
        loggedIn: false,
        loggingIn: false,
        isUserValid: false,
        addUserRequest: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case ADD_USER_REQUEST:
      return {
        ...state,
        addUserRequest: true,
      };
    case VALIDATE_USER_SUCCESS:
      return {
        ...state,
        isUserValid: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        message: action.payload,
        addUserRequest: false,
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        message: action.payload,
        addUserRequest: false,
      };
    case VALIDATE_USER_FAILED:
      return {
        data: null,
        loading: false,
        message: "",
        loggedIn: false,
        loggingIn: false,
        isUserValid: false,
        addUserRequest: false,
      };
    case LOGOUT_USER_SUCCESS:
      return {
        data: null,
        loading: false,
        message: "",
        loggedIn: false,
        loggingIn: false,
        isUserValid: false,
        addUserRequest: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        data: {},
        loggedIn: false,
        loggingIn: false,
        message: action.payload,
      };
    case RESET_ERROR_MESSAGE_USERS:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
}
