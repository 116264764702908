import React from "react";
import { useSelector } from "react-redux";
import Evidences from "./Evidences";
import Moment from "moment";
import { GetBadge, TranslatePaymentMethod } from "../helpers/Orders";

export default function Orders() {
  const Order = useSelector((store) => store.order.currentOrder);

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Pedido</h4>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th> Folio </th>
                      <th> Fecha </th>
                      <th> Estatus </th>
                      <th> Cantidad </th>
                      <th> Forma de pago </th>
                      <th> Asignado </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{Order.folio}</td>
                      <td>{Moment(Order.date).format("DD-MM-YYYY")} </td>
                      <td>
                        <label className={"badge " + GetBadge(Order.status)}>
                          {Order.status}
                        </label>
                      </td>
                      <td> {Order.amount} </td>
                      <td> {TranslatePaymentMethod(Order.paymentMethod)} </td>
                      <td> {Order.assignedTo} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Evidences folio={Order.folio} />
    </div>
  );
}
