import React, { useState } from "react";
import { getOrderAction } from "../redux/orders";
import { useSelector, useDispatch } from "react-redux";

export default function Search() {
  const fetchingOrder = useSelector((store) => store.order.fetchingOrder);
  const [submitted, setSubmitted] = useState(false);
  const [folio, setFolio] = useState("");

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    setFolio(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (folio) {
      dispatch(getOrderAction(folio));
      //this.props.login(username, password);
    }
  };

  return (
    <div className="row">
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Buscar pedidos</h4>
            <p className="card-description">
              Ingresa el número de pedido para realizar la búsqueda.
            </p>
            <form
              name="form"
              onSubmit={handleSubmit}
              className={
                "form-inline " + (submitted && !folio ? "was-validated" : "")
              }
              noValidate
            >
              <fieldset disabled={fetchingOrder && "disabled"}>
                <input
                  type="text"
                  name="folio"
                  onChange={handleChange}
                  className="form-control mb-2 mr-sm-2"
                  id="inlineFormInputName2"
                  placeholder="Número de pedido"
                  required
                />
                <button type="submit" className="btn btn-gradient-primary mb-2">
                  {fetchingOrder ? (
                    <img
                      alt="loading"
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />
                  ) : (
                    "Buscar"
                  )}
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
