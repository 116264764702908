import React from "react";

export const GetBadge = (status) => {
  switch (status) {
    case "Cancelado":
      return "badge-gradient-danger";
    case "Nuevo" || "Programado" || "Registrado":
      return "badge-gradient-info";
    case "Realizado":
      return "badge-outline-success";
    case "PorConfirmar" || "EnCancelacion":
      return "badge-gradient-warning";
    default:
      return "badge-gradient-warning";
  }
};

export const TranslatePaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case "card":
      return "Tarjeta Bancaria";
    case "cash":
      return "Efectivo";
    default:
      return paymentMethod;
  }
};
