import React, { useEffect, useState } from "react";
import { getOrdersCapturedAction, finalizeOrderAction } from "../redux/orders";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";

export default function OrdersList() {
  const dispatch = useDispatch();
  const [showDetail, setShowDetail] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [comments, setComments] = useState("");
  const [loadOrders, setLoadOrders] = useState(true);
  const orders = useSelector((store) => store.order.ordersList);
  const finalizeOrderDone = useSelector(
    (store) => store.order.finalizeOrderDone
  );
  const finalizeOrderRequest = useSelector(
    (store) => store.order.finalizeOrderRequest
  );
  const loading = useSelector((store) => store.order.loading);

  useEffect(() => {
    if (loadOrders || finalizeOrderDone) {
      dispatch(getOrdersCapturedAction());
      setLoadOrders(false);
    }
  }, [dispatch, loadOrders, setLoadOrders, finalizeOrderDone]);

  const setPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
      case "card":
        return "Tarjeta Bancaria";
      case "cash":
        return "Efectivo";
      default:
        return paymentMethod;
    }
  };

  const ServiceDetail = (order) => {
    setShowDetail(true);
    setOrderDetail(order);
  };

  const CancelButton = () => {
    setShowDetail(false);
  };

  const FinalizeService = () => {
    //console.log("Service finalized", comments);
    dispatch(finalizeOrderAction(comments, orderDetail.folio));
    setShowDetail(false);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper-o">
        {loading || finalizeOrderRequest ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {showDetail && (
              <div
                className="modal fade show"
                role="dialog"
                style={{ display: "block", overflowY: "hidden" }}
              >
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={CancelButton}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <blockquote className="blockquote blockquote-primary">
                        <p style={{ textAlign: "center", marginLeft: ".5rem" }}>
                          <b>Consumidor:</b> {orderDetail.userName} {" -  "}
                          <b>Número de folio:</b> {orderDetail.folio} {" -  "}
                          <b>Fecha:</b>{" "}
                          {Moment(orderDetail.orderDate).format("DD-MM-YYYY")}{" "}
                          {" -  "}
                          <b>Cantidad:</b> {orderDetail.amount} {" -  "}
                          <b>Total:</b> {orderDetail.paymentTotal}
                        </p>
                      </blockquote>
                      <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                          <p style={{ textAlign: "center" }}>
                            Foto medidor inicial
                          </p>
                          <img
                            src={orderDetail.evidence.imageBefore}
                            alt="imageBefore"
                          />
                        </div>
                        <div className="col-lg-4 mx-auto">
                          <p style={{ textAlign: "center" }}>
                            Foto medidor final
                          </p>
                          <img
                            src={orderDetail.evidence.imageAfter}
                            alt="imageAfter"
                          />
                        </div>
                      </div>

                      <div className="form-group" style={{ marginTop: "3em" }}>
                        <label>Comentarios:</label>
                        <textarea
                          className="form-control"
                          id="exampleTextarea1"
                          rows="4"
                          value={comments}
                          onChange={(event) => {
                            setComments(event.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={FinalizeService}
                        className="btn btn-primary"
                      >
                        Finalizar Servicio
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={CancelButton}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Servicios</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th> Folio </th>
                        <th> Fecha </th>
                        <th> Cantidad </th>
                        <th> Costo Total </th>
                        <th> Forma de pago </th>
                        <th> Asignado </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order) => {
                        return (
                          <tr key={order.folio}>
                            <td>
                              {" "}
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  ServiceDetail(order);
                                }}
                                href="/#"
                              >
                                {order.folio}
                              </a>
                            </td>
                            <td>
                              {" "}
                              {Moment(order.orderDate).format(
                                "DD-MM-YYYY"
                              )}{" "}
                            </td>
                            <td> {order.amount} </td>
                            <td> {order.paymentTotal} </td>
                            <td> {setPaymentMethod(order.paymentMethod)} </td>
                            <td> {order.assignedTo} </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
