import React, { useState } from "react";
import { UserSyncBoxUpload } from "./UserSyncBoxUpload";
import { UserSyncTableFiles } from "./UserSyncTableFiles";

export default function UsersSync() {
  const [uploadTable, setUploadTable] = useState(0);
  const onSuccesUpload = (n) => {
    setUploadTable(uploadTable + n);
  };
  return (
    <div className="main-panel">
      <div className="content-wrapper-o">
        <div className="page-header">
          <h3 className="page-title">Actualizar base de usuarios</h3>
        </div>
        <UserSyncBoxUpload onSuccesUpload={onSuccesUpload}></UserSyncBoxUpload>
        <UserSyncTableFiles uploadTable={uploadTable}></UserSyncTableFiles>
      </div>
    </div>
  );
}
