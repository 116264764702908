import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiLogout, mdiAccount } from "@mdi/js";
import { useDispatch } from "react-redux";
import { logOutAction } from "../redux/user";

export default function Header({ name: Name }) {
  const [logOutModal, setLogOutModal] = useState(false);
  const name = Name;
  const dispatch = useDispatch();

  const logOut = (event) => {
    event.preventDefault();
    setLogOutModal(true);
  };

  const CancelButton = () => {
    setLogOutModal(false);
  };

  const AcceptButton = () => {
    dispatch(logOutAction());
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo" href="index.html">
          <img
            src="https://d3huhk58c14osn.cloudfront.net/mailing/images/lgKgOriginal.png"
            alt="logo"
          />
        </a>
        {/* <a className="navbar-brand brand-logo-mini" href="index.html">
          <img
            src="https://d3huhk58c14osn.cloudfront.net/mailing/images/lgKgOriginal.png"
            alt="logo"
          />
        </a> */}
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
        >
          <span className="mdi mdi-menu"></span>
        </button>
        <div className="search-field d-none d-md-block">
          <form className="d-flex align-items-center h-100" action="#">
            <div className="input-group">
              <div className="input-group-prepend bg-transparent">
                <i className="input-group-text border-0 mdi mdi-magnify"></i>
              </div>
            </div>
          </form>
        </div>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">
            <a className="nav-link" id="profileDropdown" href="/">
              <div className="nav-profile-img">
                {/* <img src={face1} alt="image_data" /> */}
                <Icon
                  path={mdiAccount}
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="white"
                />
              </div>
              <div className="nav-profile-text">
                <p className="text-white">{name}</p>
              </div>
            </a>
          </li>
          <li className="nav-item">
            <a onClick={logOut} className="nav-link" href="/">
              <Icon
                path={mdiLogout}
                size={1}
                horizontal
                vertical
                rotate={180}
                color="white"
              />
            </a>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      {logOutModal && (
        <div
          className="modal fade show"
          role="dialog"
          style={{ display: "block", overflowY: "hidden" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Aviso</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={CancelButton}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                ¿Seguro que quieres cerrar sesión?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={AcceptButton}
                  className="btn btn-primary"
                >
                  Aceptar
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={CancelButton}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
