import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../redux/user";
import { history } from "../helpers/history";

export default function Login() {
  const [submitted, setSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const loggingIn = useSelector((store) => store.user.loggingIn);
  const message = useSelector((store) => store.user.message);
  const loggedIn = useSelector((store) => store.user.loggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      // dispatch(validateUserAction());
      history.push("/");
    }
  }, [loggedIn]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (username && password) {
      dispatch(loginAction(username, password));
      //this.props.login(username, password);
    }
  };

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <img
                    alt="Kiwigas"
                    src="https://d3huhk58c14osn.cloudfront.net/mailing/images/lgKgOriginal.png"
                  />
                </div>
                <h4>Iniciar sesión</h4>

                {message !== "" && (
                  <div className="invalid-feedback d-block">{message}</div>
                )}

                <form
                  name="form"
                  onSubmit={handleSubmit}
                  className={
                    "pt-3 needs-validation " +
                    (submitted && (!username || !password)
                      ? "was-validated"
                      : "")
                  }
                  noValidate
                >
                  <fieldset disabled={loggingIn ? "disabled" : ""}>
                    <div className="form-group">
                      <input
                        type="email"
                        name="username"
                        onChange={handleChange}
                        className="form-control form-control-lg"
                        id="username"
                        placeholder="Correo electrónico"
                        autoComplete="off"
                        required
                      />
                      <div className="invalid-feedback">
                        El correo electrónico es requerido.
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        onChange={handleChange}
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Contraseña"
                        autoComplete="off"
                        required
                      />
                      <div className="invalid-feedback">
                        La contraseña es requerida.
                      </div>
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn">
                        {loggingIn ? (
                          <img
                            alt="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                          />
                        ) : (
                          "Entrar"
                        )}
                      </button>
                      {/* {submitted && (
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    )} */}
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
