import React, {useState} from "react";
import {
  mdiCalendarCheck,
  mdiAccountHardHat,
  mdiAlignVerticalBottom,
  mdiCheckboxMarked,
  mdiAccountGroup,
  mdiMenuRight,
  mdiAccountMultiplePlus,
  mdiCloudUpload
} from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import '../styles/menu.css'

export default function Nav() {
  const [openSubmenuUsers, setOpenSubmenuUsers] = useState(false)
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item">
          <Link className="nav-link" to={{ pathname: "/" }}>
            <span className="menu-title">Pedidos</span>
            <i className="mdi mdi-table-large menu-icon">
              <Icon
                path={mdiAccountHardHat}
                size={1}
                horizontal
                vertical
                rotate={180}
                color="black"
              />
            </i>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={{ pathname: "/services" }}>
            <span className="menu-title">Servicios</span>
            <i className="mdi mdi-table-large menu-icon">
              <Icon
                path={mdiCalendarCheck}
                size={1}
                horizontal
                vertical
                rotate={180}
                color="black"
              />
            </i>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={{ pathname: "/price" }}>
            <span className="menu-title">Precio</span>
            <i className="mdi mdi-table-large menu-icon">
              <Icon
                path={mdiAlignVerticalBottom}
                size={1}
                horizontal
                vertical
                rotate={180}
                color="black"
              />
            </i>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={{ pathname: "/orders" }}>
            <span className="menu-title">Servicios finalizados</span>
            <i className="mdi mdi-table-large menu-icon">
              <Icon
                path={mdiCheckboxMarked}
                size={1}
                horizontal
                vertical
                rotate={180}
                color="black"
              />
            </i>
          </Link>
        </li>
        <li className="nav-item">
          <div className="nav-link cur-click" onClick={()=>{setOpenSubmenuUsers(!openSubmenuUsers)}}>
            <span className="menu-title">Usuarios</span>
            <i className="mdi mdi-table-large menu-icon">
              <Icon
                path={mdiAccountGroup}
                size={1}
                horizontal
                vertical
                rotate={180}
                color="black"
              />
            </i>
            <Icon
                path={mdiMenuRight }
                size={1}
                horizontal
                vertical
                rotate={openSubmenuUsers?270:180}
                color="black"
              />
          </div>
          <ul className={!openSubmenuUsers?'nav menu-close':'nav menu-open'}>
          <li className="pl-3">
              <Link className="nav-link" to={{ pathname: "/users" }}>
                <span className="menu-title">Usuarios</span>
                <i className="mdi mdi-table-large menu-icon">
                  <Icon
                    path={mdiAccountMultiplePlus}
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
                  />
                </i>
              </Link>
            </li>
            <li className="pl-3">
              <Link className="nav-link" to={{ pathname: "/users-sync" }}>
                <span className="menu-title">Procesos</span>
                <i className="mdi mdi-table-large menu-icon">
                  <Icon
                    path={mdiCloudUpload }
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
                  />
                </i>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}
