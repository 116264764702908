import React, { useEffect, useState } from "react";
import { getLogSyncCSVAction } from "../redux/orders";
import { useDispatch, useSelector } from "react-redux";
import {
  mdiLoading,
  mdiCheckBold,
  mdiAlertCircleOutline,
  mdiRefresh,
  mdiHelp,
  mdiPackageVariant
} from "@mdi/js";
import Icon from "@mdi/react";

const styleRefreshButton = {
  position: "absolute",
  marginTop: "-60px",
  right: "40px",
};

export const UserSyncTableFiles = ({ uploadTable }) => {
  const dispatch = useDispatch();
  const logData = useSelector((store) => store.order.logSyncCSV);
  const loadingDispatch = useSelector((store) => store.order.loading);
  const [tableData, setTableData] = useState([]);
  const refreshTable = () => {
    dispatch(getLogSyncCSVAction());
  };

  useEffect(() => {
    if (uploadTable) {
      refreshTable();
    }
  }, [uploadTable])


  useEffect(() => {
    refreshTable();
  }, []);

  useEffect(() => {
    if (logData) {
      const { data: tempData } = logData;
      setData(tempData);
    }

  }, [logData, dispatch]);

  const setData = (data) => {
    if (data) {
      const newData = data.map((ele) => {
        return {
          id: ele.id.s,
          create_at: ele.create_at.s,
          created_by: ele.created_by.s,
          file: ele.file.s,
          state: ele.state.s,
        };
      });
      setTableData(newData);
    }
  };

  const getComponentStatus = (state) => {
    switch (state) {
      case "waiting":
        return <Icon path={mdiLoading} size={1} color="blue" spin />;
      case "finish":
        return <Icon path={mdiCheckBold} size={1} color="green" />;
      case "fail":
        return <Icon path={mdiAlertCircleOutline} size={1} color="red" />;
      default:
        return <Icon path={mdiHelp} size={1} color="gray" />;
    }
  };

  const formatDate = (stringDate) => {
    const [tempsd] = stringDate.replace(' ','T').split('.');
    const nows = new Date(`${tempsd}.000Z`);
    return nows.toLocaleString('es-MX',{ timeZone: 'America/Mexico_City', dateStyle: "short", timeStyle: 'short'});
  }

  const formatFileName = (stringName) => {
    const tempsn = stringName.split('/');
    const tempsn2 = tempsn[tempsn.length-1];
    return tempsn2
  }

  return (
    <div className="card mt-4">
      <div className="card-body">
        <h4 className="card-title">Archivos cargados.</h4>
        <p className="card-description">Historial de archivos y su estado</p>
        <div style={styleRefreshButton}>
          <button
            type="button"
            className="btn btn-gradient-primary btn-sm"
            onClick={refreshTable}
            disabled={loadingDispatch}
          >
            {loadingDispatch ? (
              <Icon path={mdiRefresh} size={1} color="white" spin />
            ) : (
              <Icon path={mdiRefresh} size={1} color="white" />
            )}
          </button>
        </div>
        {loadingDispatch && (
          <div className="d-flex justify-content-center">
            <Icon path={mdiLoading} size={5} color="gray" spin />
          </div>
        )}
        {!loadingDispatch && (
          <table className="table table-striped table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">Archivo</th>
                <th scope="col">Fecha</th>
                <th scope="col">Cargado por</th>
                <th scope="col">Estatus</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => {
                return (
                  <tr key={`${item.id}`}>
                    <th scope="row">{formatFileName(item.file)}</th>
                    <td>{formatDate(item.create_at)}</td>
                    <td>{item.created_by}</td>
                    <td>{getComponentStatus(item.state)}</td>
                  </tr>
                );
              })}
              {tableData.length === 0 && <tr>
                <td colSpan={4} className="text-center table-light">
                  <Icon path={mdiPackageVariant} size={8} color="gray" />
                  <br></br>
                    No hay elementos para mostrar.
                </td>
              </tr>}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
