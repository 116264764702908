import {
  fetchOrder,
  AddEvidences,
  fetchOrdersCaptured,
  fetchOrdersCompleted,
  finalizeOrder,
  setPrice,
  getPrice,
  fetchOrderCompletedByFolio,
  setCost,
  getCost,
  fetchSyncLogCSV
} from "../services";

const initialState = {
  saved: false,
  loading: false,
  fetchingOrder: false,
  currentOrder: null,
  searchOrder: null,
  ordersList: [],
  message: null,
  finalizeOrderDone: false,
  savingPrice: false,
  savingCost: false,
  currentPrice: {},
  currentCost: {},
  fetchingPrice: false,
  fetchingCost: false,
  ordersCompletedList: [],
  logSyncCSV:[],
};

const SAVE_EVIDENCE_SUCCESS = "SAVE_EVIDENCE_SUCCESS";
const SAVE_EVIDENCE_REQUEST = "SAVE_EVIDENCE_REQUEST";
const SAVE_EVIDENCE_FAILED = "SAVE_EVIDENCE_FAILED";
const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
const FETCH_ORDER_FAILED = "FETCH_ORDER_FAILED";
const FETCH_ORDER_COMPLETED_REQUEST = "FETCH_ORDER_COMPLETED_REQUEST";
const FETCH_ORDER_COMPLETED_SUCCESS = "FETCH_ORDER_COMPLETED_SUCCESS";
const FETCH_ORDER_COMPLETED_FAILED = "FETCH_ORDER_COMPLETED_FAILED";
const FETCH_ORDERS_CAPTURED_REQUEST = "FETCH_ORDERS_CAPTURED_REQUEST";
const FETCH_ORDERS_CAPTURED_SUCCESS = "FETCH_ORDERS_CAPTURED_SUCCESS";
const FETCH_ORDERS_CAPTURED_FAILED = "FETCH_ORDERS_CAPTURED_FAILED";
const FINALIZE_ORDER_REQUEST = "FINALIZE_ORDER_REQUEST";
const FINALIZE_ORDER_SUCCESS = "FINALIZE_ORDER_SUCCESS";
const FINALIZE_ORDER_FAILED = "FINALIZE_ORDER_FAILED";
const SAVE_PRICE_REQUEST = "SAVE_PRICE_REQUEST";
const SAVE_PRICE_SUCCESS = "SAVE_PRICE_SUCCESS";
const SAVE_PRICE_FAILED = "SAVE_PRICE_FAILED";
const SAVE_COST_REQUEST = "SAVE_COST_REQUEST";
const SAVE_COST_SUCCESS = "SAVE_COST_SUCCESS";
const SAVE_COST_FAILED = "SAVE_COST_FAILED";
const FETCH_PRICE_REQUEST = "FETCH_PRICE_REQUEST";
const FETCH_PRICE_SUCCESS = "FETCH_PRICE_SUCCESS";
const FETCH_PRICE_FAILED = "FETCH_PRICE_FAILED";
const FETCH_COST_REQUEST = "FETCH_COST_REQUEST";
const FETCH_COST_SUCCESS = "FETCH_COST_SUCCESS";
const FETCH_COST_FAILED = "FETCH_COST_FAILED";
const FETCH_ORDERS_COMPLETED_REQUEST = "FETCH_ORDERS_COMPLETED_REQUEST";
const FETCH_ORDERS_COMPLETED_SUCCESS = "FETCH_ORDERS_COMPLETED_SUCCESS";
const FETCH_ORDERS_COMPLETED_FAILED = "FETCH_ORDERS_COMPLETED_FAILED";
const RESET_SEARCH_BOX_ORDERS = "RESET_SEARCH_BOX_ORDERS";
const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";
const FETCH_LOG_SYNC_CSV_REQUEST = "FETCH_LOG_SYNC_CSV_REQUEST";
const FETCH_LOG_SYNC_CSV_SUCCESS = "FETCH_LOG_SYNC_CSV_SUCCESS";
const FETCH_LOG_SYNC_CSV_FAILED = "FETCH_LOG_SYNC_CSV_FAILED";

export const getOrderAction = (folio) => (dispatch) => {
  dispatch({
    type: FETCH_ORDER_REQUEST,
  });
  return fetchOrder(folio)
    .then((orderResponse) => {
      if (orderResponse.status === 200) {
        console.log("orderResponse.data", orderResponse.data);
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: orderResponse.data,
        });
      } else {
        dispatch({
          type: FETCH_ORDER_FAILED,
          payload: orderResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: error.response.data.message,
      });
    });
};

export const getOrderCompletedAction = (folio) => (dispatch) => {
  dispatch({
    type: FETCH_ORDER_COMPLETED_REQUEST,
  });
  return fetchOrderCompletedByFolio(folio)
    .then((orderResponse) => {
      if (orderResponse.status === 200) {
        dispatch({
          type: FETCH_ORDER_COMPLETED_SUCCESS,
          payload: orderResponse.data,
        });
      } else {
        dispatch({
          type: FETCH_ORDER_COMPLETED_FAILED,
          payload: orderResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ORDER_COMPLETED_FAILED,
        payload: "No ha sido posible encontrar la orden.",
      });
    });
};

export const saveEvidenceAction = (orderNumber, imageBefore, imageAfter) => (
  dispatch,
  getState
) => {
  dispatch({
    type: SAVE_EVIDENCE_REQUEST,
  });

  let evidenceInput = {
    folio: orderNumber,
    initialImage: imageBefore,
    finalImage: imageAfter,
  };

  return AddEvidences(evidenceInput)
    .then(() => {
      dispatch({
        type: SAVE_EVIDENCE_SUCCESS,
      });
    })
    .catch((error) => {
      let errorInfo;
      if (!error.response.data.message) {
        errorInfo =
          "Lo sentimos, ha ocurrido un error al realizar la operación, intente de nuevo más tarde.";
      } else {
        errorInfo = error.response.data.message;
      }
      dispatch({
        type: SAVE_EVIDENCE_FAILED,
        payload: errorInfo,
      });
    });
};

export const getOrdersCapturedAction = () => (dispatch) => {
  dispatch({
    type: FETCH_ORDERS_CAPTURED_REQUEST,
  });

  return fetchOrdersCaptured()
    .then((ordersResponse) => {
      if (ordersResponse.status === 200) {
        dispatch({
          type: FETCH_ORDERS_CAPTURED_SUCCESS,
          payload: ordersResponse.data,
        });
      } else {
        dispatch({
          type: FETCH_ORDERS_CAPTURED_FAILED,
          payload: ordersResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ORDERS_CAPTURED_FAILED,
        payload: error.response.data.message,
      });
    });
};

export const getOrdersCompletedAction = () => (dispatch) => {
  dispatch({
    type: FETCH_ORDERS_COMPLETED_REQUEST,
  });

  return fetchOrdersCompleted()
    .then((ordersCompletedResponse) => {
      if (ordersCompletedResponse.status === 200) {
        dispatch({
          type: FETCH_ORDERS_COMPLETED_SUCCESS,
          payload: ordersCompletedResponse.data.orderHistory,
        });
      } else {
        dispatch({
          type: FETCH_ORDERS_CAPTURED_FAILED,
          payload: ordersCompletedResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ORDERS_COMPLETED_FAILED,
        payload:
          "Ha ocurrido un error en el servicio, intente nuevamente más tarde",
      });
    });
};
export const finalizeOrderAction = (comments, folio) => (
  dispatch,
  getState
) => {
  dispatch({
    type: FINALIZE_ORDER_REQUEST,
  });

  let finalizeOrderInput = {
    comments: comments,
    finalizeOrder: true,
    folio: folio,
  };

  return finalizeOrder(finalizeOrderInput)
    .then((finalizeOrderResponse) => {
      if (finalizeOrderResponse.status === 200) {
        dispatch({
          type: FINALIZE_ORDER_SUCCESS,
          payload: finalizeOrderResponse.data.message,
        });
      } else {
        dispatch({
          type: FINALIZE_ORDER_FAILED,
          payload: finalizeOrderResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FINALIZE_ORDER_FAILED,
        payload: error.response.data.message,
      });
    });
};

export const savePriceAction = (value) => (dispatch) => {
  dispatch({
    type: SAVE_PRICE_REQUEST,
  });
  let price = parseInt(value * 100);
  return setPrice(price)
    .then((setPriceResponse) => {
      if (setPriceResponse.status === 200) {
        return getPrice().then((fetchPriceResponse) => {
          if (fetchPriceResponse.status === 200) {
            dispatch({
              type: SAVE_PRICE_SUCCESS,
              payload: {
                message: setPriceResponse.data.message,
                priceResponse: fetchPriceResponse.data,
              },
            });
          }
        });
      } else {
        dispatch({
          type: SAVE_PRICE_FAILED,
          payload: setPriceResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SAVE_PRICE_FAILED,
        payload: "No ha sido posible guardar la información.",
      });
    });
};

export const getPriceAction = () => (dispatch) => {
  dispatch({
    type: FETCH_PRICE_REQUEST,
  });
  return getPrice()
    .then((fetchPriceResponse) => {
      if (fetchPriceResponse.status === 200) {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          payload: fetchPriceResponse.data,
        });
      } else {
        dispatch({
          type: FETCH_PRICE_FAILED,
          payload: fetchPriceResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PRICE_FAILED,
        payload: "No ha sido posible guardar la información.",
      });
    });
};

export const saveCostAction = (value) => (dispatch) => {
  dispatch({
    type: SAVE_COST_REQUEST,
  });

  let PriceInCents = parseInt(value?.PriceInCents * 100);
  let payload = { ...value, PriceInCents };


  return setCost(payload)
    .then((setCostResponse) => {
      if (setCostResponse.status === 200) {
        return getCost().then((fetchPriceResponse) => {
          if (fetchPriceResponse.status === 200) {
            dispatch({
              type: SAVE_COST_SUCCESS,
              payload: {
                message: setCostResponse.data.message,
                priceResponse: fetchPriceResponse.data,
              },
            });
          }
        });
      } else {
        dispatch({
          type: SAVE_COST_FAILED,
          payload: setCostResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SAVE_COST_FAILED,
        payload: "No ha sido posible guardar la información.",
      });
    });
};

export const getCostAction = () => (dispatch) => {
  dispatch({
    type: FETCH_COST_REQUEST,
  });
  return getCost()
    .then((fetchCostResponse) => {
      if (fetchCostResponse.status === 200) {
        dispatch({
          type: FETCH_COST_SUCCESS,
          payload: fetchCostResponse.data,
        });
      } else {
        dispatch({
          type: FETCH_COST_FAILED,
          payload: fetchCostResponse.data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_COST_FAILED,
        payload: "No ha sido posible guardar la información.",
      });
    });
};

export const getLogSyncCSVAction = () => (dispatch) => {
  dispatch({
    type: FETCH_LOG_SYNC_CSV_REQUEST,
  });

  return fetchSyncLogCSV()
    .then((fetchSyncLogCSV) => {
      if (fetchSyncLogCSV.status === 200) {
        dispatch({
          type: FETCH_LOG_SYNC_CSV_SUCCESS,
          payload: fetchSyncLogCSV.data,
        });
      } else {
        dispatch({
          type: FETCH_LOG_SYNC_CSV_FAILED,
          payload: fetchSyncLogCSV.data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_LOG_SYNC_CSV_FAILED,
        payload:
          "Ha ocurrido un error en el servicio, intente nuevamente más tarde",
      });
    });
};

export const resetSearchBox = () => (dispatch) => {
  dispatch({ type: RESET_SEARCH_BOX_ORDERS });
};

export const resetErrorMessage = () => (dispatch) => {
  dispatch({ type: RESET_ERROR_MESSAGE });
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_EVIDENCE_REQUEST:
      return {
        ...state,
        saved: false,
        loading: true,
        message: null,
      };
    case SAVE_EVIDENCE_SUCCESS:
      return {
        ...state,
        saved: true,
        loading: false,
        message: null,
      };
    case SAVE_EVIDENCE_FAILED:
      return {
        ...state,
        saved: false,
        loading: false,
        message: action.payload,
      };
    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        fetchingOrder: true,
        message: null,
      };
    case FETCH_ORDER_COMPLETED_REQUEST:
      return {
        ...state,
        fetchingOrder: true,
        message: null,
      };
    case SAVE_PRICE_REQUEST:
      return {
        ...state,
        savingPrice: true,
        message: null,
      };
    case SAVE_COST_REQUEST:
      return {
        ...state,
        savingCost: true,
        message: null,
      };
      
    case FETCH_ORDERS_CAPTURED_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case FETCH_ORDERS_COMPLETED_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        fetchingOrder: false,
        currentOrder: action.payload,
      };
    case FETCH_ORDER_COMPLETED_SUCCESS:
      return {
        ...state,
        fetchingOrder: false,
        searchOrder: action.payload,
      };
    case RESET_SEARCH_BOX_ORDERS:
      return {
        ...state,
        fetchingOrder: false,
        searchOrder: null,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case SAVE_PRICE_SUCCESS:
      return {
        ...state,
        savingPrice: false,
        message: action.payload.message,
        currentPrice: action.payload.priceResponse,
      };
    case SAVE_COST_SUCCESS:
      return {
        ...state,
        savingCost: false,
        message: action.payload.message,
        currentCost: action.payload.priceResponse,
      };
    case FETCH_PRICE_REQUEST:
      return {
        ...state,
        fetchingPrice: true,
      };
    case FETCH_COST_REQUEST:
      return {
        ...state,
        fetchingCost: true,
      };
    case FETCH_PRICE_SUCCESS:
      return {
        ...state,
        fetchingPrice: false,
        currentPrice: action.payload,
      };
    case FETCH_COST_SUCCESS:
      return {
        ...state,
        fetchingCost: false,
        currentCost: action.payload,
      };
    case FETCH_ORDERS_CAPTURED_SUCCESS:
      return {
        ...state,
        loading: false,
        ordersList: action.payload,
      };
    case FETCH_ORDERS_COMPLETED_SUCCESS:
      return {
        ...state,
        loading: false,
        ordersCompletedList: action.payload,
      };
    case FETCH_ORDER_FAILED:
      return {
        ...state,
        message: action.payload,
        fetchingOrder: false,
      };
    case FETCH_ORDER_COMPLETED_FAILED:
      return {
        ...state,
        message: action.payload,
        fetchingOrder: false,
      };
    case SAVE_PRICE_FAILED:
      return {
        ...state,
        message: action.payload,
        savingPrice: false,
      };
    case SAVE_COST_FAILED:
      return {
        ...state,
        message: action.payload,
        savingCost: false,
      };

      
    case FETCH_ORDERS_CAPTURED_FAILED:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case FINALIZE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case FINALIZE_ORDER_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
        finalizeOrderDone: true,
      };
    case FINALIZE_ORDER_FAILED:
      return {
        ...state,
        message: action.payload,
        loading: false,
        finalizeOrderDone: true,
      };
    case FETCH_LOG_SYNC_CSV_REQUEST:
        return {
          ...state,
          loading: true,
          logSyncCSV: [],
        };
    case FETCH_LOG_SYNC_CSV_SUCCESS:
          return {
            ...state,
            loading: false,
            logSyncCSV: action.payload,
          };
    case FETCH_LOG_SYNC_CSV_FAILED:
            return {
              ...state,
              loading: false,
              logSyncCSV: []
            };
    default:
      return state;
  }
}
