import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserAction, validateUserAction } from "../redux/user";

export const PrivateRoute = ({ component: Component, role: Role }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.data);
  if (user === null) {
    dispatch(setUserAction());
  } else {
    dispatch(validateUserAction());
  }
  return (
    <Route
      render={() =>
        (user !== null &&
          ((Role === "cms" && user?.role === "Cms") ||
            user?.role === "Admin")) ||
        (Role === "admin" && user?.role === "Admin") ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};
