import React from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import Nav from "./Nav";
import RegisterUser from "./RegisterUser";
export default function Users() {
  const user = useSelector((store) => store.user.data);
  return (
    <div className="container-scroller">
      <Header name={`${user.firstName} ${user.lastName}`}></Header>
      <div className="container-fluid page-body-wrapper">
        <Nav></Nav>
        <RegisterUser></RegisterUser>
      </div>
    </div>
  );
}
