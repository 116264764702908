import React, { useState } from "react";
import { resetErrorMessage } from "../redux/orders";
import { resetErrorMessageUsers } from "../redux/user";
import { useDispatch } from "react-redux";

export default function Modal(props) {
  const [showModal, setShowModal] = useState(props.openModal);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(resetErrorMessage());
    dispatch(resetErrorMessageUsers());
    setShowModal(false);
  };

  return (
    showModal && (
      <div
        className="modal fade show"
        id="myModal"
        style={{ display: "block", overflowY: "hidden" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Aviso</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">{props.message}</div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
