import React, { useState } from "react";
import { SaveUserAction } from "../redux/user";
import { useSelector, useDispatch } from "react-redux";
import Modal from "./Modal";

export default function RegisterUser() {
  const dispatch = useDispatch();

  const [formName, setFormName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [formSgcNumber, setFormSgcNumber] = useState("");
  const [formMembershipNumber, setFormMembershipNumber] = useState("");
  const [formPhoneNumber, setFormPhoneNumber] = useState("");

  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [sgcNumberError, setSgcNumberError] = useState("");
  const [membershipNumberError, setMembershipNumberError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const message = useSelector((store) => store.user.message);
  const addUserRequest = useSelector((store) => store.user.addUserRequest);

  const validNameRegex = RegExp(
    /^[a-zA-ZÁÉÍÓÚáéíóúÑñ]+(([',. -][a-zA-ZÁÉÍÓÚáéíóúÑñ ])?[a-zA-ZÁÉÍÓÚáéíóúÑñ]*)*$/g
  );

  const validNumberRegex = RegExp(/^[0-9]*$/gm);

  const handleChange = (event) => {
    const { name, value } = event.target;

    let errorMessage = "El campo no tiene un formato válido.";

    switch (name) {
      case "name":
        setNameError(validNameRegex.test(value) ? "" : errorMessage);
        setFormName(value);
        return;
      case "lastName":
        setLastNameError(validNameRegex.test(value) ? "" : errorMessage);
        setFormLastName(value);
        return;
      case "sgcNumber":
        setSgcNumberError(validNumberRegex.test(value) ? "" : errorMessage);
        setFormSgcNumber(value);
        return;
      case "membershipNumber":
        setMembershipNumberError(
          validNumberRegex.test(value) ? "" : errorMessage
        );
        setFormMembershipNumber(value);
        return;
      case "phoneNumber":
        setPhoneNumberError(validNumberRegex.test(value) ? "" : errorMessage);
        setFormPhoneNumber(value);
        return;
      default:
        break;
    }
  };

  const handleCancelForm = (event) => {
    event.preventDefault();
    setFormName("");
    setFormLastName("");
    setFormSgcNumber("");
    setFormMembershipNumber("");
    setFormPhoneNumber("");
  };

  const submitForm = (event) => {
    event.preventDefault();

    let emptyField = false;

    if (!formName) {
      setNameError("El nombre es requerido.");
      emptyField = true;
    }

    if (!formLastName) {
      setLastNameError("El apellido es requerido.");
      emptyField = true;
    }

    if (!formSgcNumber) {
      setSgcNumberError("El número de contrato de SGC es requerido.");
      emptyField = true;
    }

    if (!formPhoneNumber) {
      setPhoneNumberError("El número telefónico es requerido.");
      emptyField = true;
    }

    if (!emptyField && !nameError && !lastNameError && !sgcNumberError) {
      dispatch(
        SaveUserAction(
          formName,
          formLastName,
          formSgcNumber,
          formMembershipNumber,
          formPhoneNumber
        )
      );
    }
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper-o">
      <div className="page-header">
          <h3 className="page-title">Registrar usuarios</h3>
        </div>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Prerregistro de la aplicación móvil.</h4>
            <form className="forms-sample" onSubmit={submitForm} noValidate>
              <fieldset disabled={addUserRequest ? "disabled" : ""}>
                <div className="form-group">
                  <label>Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nombre"
                    onChange={handleChange}
                    name="name"
                    value={formName}
                    autoComplete="off"
                    required
                  />
                  {nameError && (
                    <div className="invalid-feedback d-block">{nameError}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Apellidos</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Apellidos"
                    onChange={handleChange}
                    name="lastName"
                    value={formLastName}
                    autoComplete="off"
                    required
                  />
                  {lastNameError && (
                    <div className="invalid-feedback d-block">
                      {lastNameError}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>Número de contrato de SGC</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sgcNumber"
                    placeholder="Número de contrato"
                    maxLength="16"
                    onChange={handleChange}
                    name="sgcNumber"
                    value={formSgcNumber}
                    autoComplete="off"
                    required
                  />
                  {sgcNumberError && (
                    <div className="invalid-feedback d-block">
                      {sgcNumberError}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>Número de membresía</label>
                  <input
                    type="text"
                    className="form-control"
                    id="membershipNumber"
                    maxLength="16"
                    placeholder="Número de membresía"
                    onChange={handleChange}
                    name="membershipNumber"
                    value={formMembershipNumber}
                    autoComplete="off"
                    required
                  />
                  {membershipNumberError && (
                    <div className="invalid-feedback d-block">
                      {membershipNumberError}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>Número telefónico</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNumber"
                    maxLength="10"
                    placeholder="Número telefónico"
                    onChange={handleChange}
                    name="phoneNumber"
                    value={formPhoneNumber}
                    autoComplete="off"
                    required
                  />
                  {phoneNumberError && (
                    <div className="invalid-feedback d-block">
                      {phoneNumberError}
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-gradient-primary mr-2">
                  Guardar
                </button>
                <button className="btn btn-light" onClick={handleCancelForm}>
                  Cancel
                </button>
              </fieldset>
            </form>
          </div>
        </div>
        {message && <Modal message={message} openModal={true}></Modal>}
      </div>
    </div>
  );
}
