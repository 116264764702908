import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { history } from "./helpers/history";
import { PrivateRoute } from "./helpers/PrivateRoute";
import Services from "./components/Services";
import Price from "./components/Price";
import OrdersCompleted from "./components/OrdersCompleted";
import Users from "./components/Users";
import UsersSync from "./components/UsersSync";
import "./styles/App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    history.listen((location, action) => {});
  }

  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <PrivateRoute exact path="/" component={Home} role="cms" />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/services" component={Services} role="admin" />
            <PrivateRoute path="/price" component={Price} role="admin" />
            <PrivateRoute
              path="/orders"
              component={OrdersCompleted}
              role="admin"
            />
            <PrivateRoute path="/users" component={Users} role="admin" />
            <PrivateRoute path="/users-sync" component={UsersSync} role="admin" />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
