import axios from "axios";
import AuthHeader from "../helpers/AuthHeader";

const url_api = window.location.hostname === 'localhost'?
                "http://localhost:59955" : process.env.REACT_APP_KIWI_BASE_API;

axios.defaults.timeout = 200000;

export const login = (data) => {
  return axios.post(
    `${url_api}/api/user/authenticate`,
    JSON.stringify(data),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const fetchOrder = (folio) => {
  return axios.get(
    `${url_api}/api/order/${folio}`,
    {
      headers: AuthHeader(),
    }
  );
};

export const validateSession = () => {
  return axios.get(
    `${url_api}/api/user/validate`,
    {
      headers: AuthHeader(),
    }
  );
};

export const AddEvidences = (data) => {
  return axios.post(
    `${url_api}/api/order/evidences`,
    JSON.stringify(data),
    {
      headers: AuthHeader(),
    }
  );
};

export const fetchOrdersCaptured = () => {
  return axios.get(
    `${url_api}/api/order/captured`,
    {
      headers: AuthHeader(),
    }
  );
};

export const finalizeOrder = (data) => {
  return axios.post(
    `${url_api}/api/order/finalize`,
    JSON.stringify(data),
    {
      headers: AuthHeader(),
    }
  );
};

export const setPrice = (data) => {
  return axios.post(
    `${url_api}/api/price`,
    JSON.stringify(data),
    {
      headers: AuthHeader(),
    }
  );
};
export const setCost = (data) => {
  return axios.post(
    `${url_api}/api/cost`,
    JSON.stringify(data),
    {
      headers: AuthHeader(),
    }
  );
};
export const getPrice = () => {
  return axios.get(
    `${url_api}/api/price`,
    {
      headers: AuthHeader(),
    }
  );
};
export const getCost = () => {
  return axios.get(
    `${url_api}/api/cost`,
    {
      headers: AuthHeader(),
    }
  );
};
export const fetchOrdersCompleted = () => {
  return axios.get(
    `${url_api}/api/order/completed`,
    {
      headers: AuthHeader(),
    }
  );
};

export const fetchOrderCompletedByFolio = (folio) => {
  return axios.get(
    `${url_api}/api/order/search/${folio}`,
    {
      headers: AuthHeader(),
    }
  );
};

export const AddUserTemp = (data) => {
  return axios.post(
    `${url_api}/api/user/register_temp`,
    JSON.stringify(data),
    {
      headers: AuthHeader(),
    }
  );
};

export const UploadCsv = (name,file) => {
  let FormData = require('form-data');
  let data = new FormData();
  data.append('Name', name);
  data.append('File', file);

  return axios.post(
    `${url_api}/api/user/register_temp`,
    data,
    {
      headers: AuthHeader(),
      ...data.getHeaders()
    }
  );
};


export const fetchSyncLogCSV = () => {
  return axios.get(
    `${url_api}/api/tools/users/sync-log`,
    {
      headers: AuthHeader(),
    }
  );
};