import React from "react";
import { useSelector } from "react-redux";
import Search from "./Search";
import Orders from "./Orders";
import Modal from "./Modal";

export default function SearchOrders() {
  const fetchingOrder = useSelector((store) => store.order.fetchingOrder);
  const { currentOrder, message } = useSelector((store) => store.order);
  return (
    <div className="main-panel">
      <div className="content-wrapper-o">
        <div className="page-header">
          <h3 className="page-title">Pedidos </h3>
        </div>
        <Search></Search>
        {message && <Modal message={message} openModal={true}></Modal>}
        {currentOrder && !fetchingOrder && <Orders />}
      </div>
    </div>
  );
}
